import React from "react"

import { Header, ReviewsContent } from "../components"

// store
import useStore from "../store"

import SEO from "./SEO"

export default function Reviews() {
  const current = useStore(state => state.current)

  return (
    <div>
      <SEO />
      <Header />
      <ReviewsContent content={current.home.target.reviews} />
    </div>
  )
}
